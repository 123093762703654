import {DiscountType} from "./DiscountType";

export default class CouponForm {
    name: string;
    code: string;
    discountValue: number;
    discountType: DiscountType;
    activationDate: Date | null;
    expirationDate: Date | null;
    maxUses: number | null;
    active: boolean;
    allowedSubscriptionIds: number[];
    allowedUserDomain: string | null;
    secretFieldName: string | null;
    secretFieldRegex: string | null;

    constructor(
        name: string,
        code: string,
        discountValue: number,
        discountType: DiscountType,
        activationDate: Date | null,
        expirationDate: Date | null,
        maxUses: number | null,
        active: boolean,
        allowedSubscriptionIds: number[],
        allowedUserDomain: string | null,
        secretFieldName: string | null,
        secretFieldRegex: string | null
    ) {
        this.name = name;
        this.code = code;
        this.discountValue = discountValue;
        this.discountType = discountType;
        this.activationDate = activationDate;
        this.expirationDate = expirationDate;
        this.maxUses = maxUses;
        this.active = active;
        this.allowedSubscriptionIds = allowedSubscriptionIds;
        this.allowedUserDomain = allowedUserDomain;
        this.secretFieldName = secretFieldName;
        this.secretFieldRegex = secretFieldRegex;
    }
}